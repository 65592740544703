import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

gsap.registerPlugin(ScrollTrigger)

const handleDisplay = (swiper, controls, container) => {
  if (swiper.isBeginning && swiper.isEnd) {
    if (controls) {
      controls.classList.add('force-hidden')
    }

    container.classList.remove('fluid-right')
    container.querySelector('.swiper-wrapper').classList.add('justify-center')
    container.closest('.element-ourresults').classList.remove('has-controls')
  } else {
    if (controls) {
      controls.classList.remove('force-hidden')
    }

    container.classList.add('fluid-right')
    container.querySelector('.swiper-wrapper').classList.remove('justify-center')
    container.closest('.element-ourresults').classList.add('has-controls')
  }
}

const init = async () => {
  const sliders = document.querySelectorAll('.our-results-slider')

  if (sliders.length) {
    import('swiper/css')
    const { default: Swiper, Navigation, Pagination } = await import('swiper')

    Swiper.use([Navigation, Pagination])

    sliders.forEach((slider) => {
      const container = slider.querySelector('.swiper')
      const numSlides = slider.querySelectorAll('.swiper-slide').length
      const controls = slider.querySelector('.slider-controls')

      if (numSlides > 1) {
        new Swiper(container, {
          slidesPerView: 'auto',
          slidesPerGroupAuto: true,
          preloadImages: false,
          simulateTouch: false,
          pagination: {
            el: slider.querySelector('.slider-pagination'),
            type: 'custom',
            renderCustom: (_, current, total) => {
              return `${current} of ${total}`
            },
          },
          navigation: {
            nextEl: slider.querySelector('.slider-control-next'),
            prevEl: slider.querySelector('.slider-control-prev'),
          },
          on: {
            resize: (swiper) => handleDisplay(swiper, controls, container),
            afterInit: (swiper) => handleDisplay(swiper, controls, container),
          },
        })
      }
    })
  }

  const elements = document.querySelectorAll('.element-ourresults')

  elements.forEach((el) => {
    if (el.dataset.disableAnimations !== undefined) {
      return
    }

    const statsWithBar = el.querySelectorAll('.stat-with-bar')

    if (statsWithBar.length) {
      statsWithBar.forEach((bar) => {
        gsap.set(bar, { height: 'auto' })
      })

      gsap.to(statsWithBar, {
        height: '100%',
        duration: 0.8,
        stagger: 0.2,
        paused: true,
        ease: 'power4',
        scrollTrigger: { trigger: el, start: 'top 80%' },
      })
    }
  })
}

init()
